import { message } from 'antd'
import { fetchCollection, normalizeDoc, uploadImage, getSlug, slugify } from './common'
import {
  CREATE_ARTICLE_SUCCESS,
  CREATE_ARTICLE_ERROR,
  UPDATE_ARTICLE_SUCCESS,
  UPDATE_ARTICLE_ERROR,
  FETCH_ARTICLES,
  FETCH_CURRENT_ARTICLE,
  CLEAR_CURRENT_ARTICLE,
  START_LOADING,
  STOP_LOADING,
} from '../action-types'

export const createArticle = ({
  slug,
  title,
  subHeading,
  excerpt,
  content,
  images,
  date,
  thumbnailImage,
  featuredImage,
  categories,
  tags,
  status,
  lang,
}) => {
  return async (dispatch, _, { getFirebase, getFirestore }) => {
    const firebase = getFirebase()
    const firestore = getFirestore()

    dispatch({ type: START_LOADING })
    const uid = firebase.auth().currentUser.uid

    try {
      const _images = []

      if (images.length > 0) {
        const storageRef = firebase.storage().ref()

        for (let file of images) {
          const image = await uploadImage(file, uid, storageRef)

          _images.push(image)
        }
      }

      const newArticle = {
        slug: lang === 'en' ? getSlug(title) : slugify(slug),
        title,
        subHeading,
        excerpt,
        content,
        categories,
        tags,
        status,
        images: _images,
        thumbnailImage,
        featuredImage,
        date,
        userRef: firestore.collection('users').doc(uid),
        createdDate: new Date(),
        modifiedDate: new Date(),
        lang,
      }

      await firestore.collection('articles').add(newArticle)

      message.success(`Insight "${title}" created!"`)
      dispatch({ type: CREATE_ARTICLE_SUCCESS })
    } catch (error) {
      message.error(error.message)
      dispatch({ type: CREATE_ARTICLE_ERROR })
    } finally {
      dispatch({ type: STOP_LOADING })
    }
  }
}

export const editArticle = ({
  slug,
  date,
  id,
  title,
  subHeading,
  images,
  thumbnailImage,
  featuredImage,
  content,
  excerpt,
  categories,
  tags,
  status,
}) => {
  return async (dispatch, _, { getFirebase, getFirestore }) => {
    const firebase = getFirebase()
    const firestore = getFirestore()

    dispatch({ type: START_LOADING })
    const uid = firebase.auth().currentUser.uid

    try {
      const _images = []

      if (images.length > 0) {
        const storageRef = firebase.storage().ref()

        for (let file of images) {
          if (file?.ref) {
            _images.push(file)
          } else {
            const image = await uploadImage(file, uid, storageRef)

            _images.push(image)
          }
        }
      }

      const updatedArticle = {
        slug: slugify(slug),
        title,
        subHeading,
        content,
        excerpt,
        categories,
        tags,
        date,
        status,
        thumbnailImage,
        featuredImage,
        images: _images,
        modifiedDate: new Date(),
      }

      await firestore.collection('articles').doc(id).update(updatedArticle)

      message.success(`Insight "${title}" edited!"`)
      dispatch({ type: UPDATE_ARTICLE_SUCCESS })
    } catch (error) {
      message.error(error.message)
      dispatch({ type: UPDATE_ARTICLE_ERROR })
    } finally {
      dispatch({ type: STOP_LOADING })
    }
  }
}

export const toggleArticle = ({ id, title, status: _status }) => {
  return async (dispatch, _, { getFirestore }) => {
    const firestore = getFirestore()

    dispatch({ type: START_LOADING })

    try {
      const status = !_status

      let date = new Date()
      if (!status) date = null

      await firestore.collection('articles').doc(id).update({ status, date })

      message.success(`Insight "${title}" ${status ? 'Published' : 'In Progress'}!"`)
      dispatch({ type: UPDATE_ARTICLE_SUCCESS })
    } catch (error) {
      message.error(error.message)
      dispatch({ type: UPDATE_ARTICLE_ERROR })
    } finally {
      dispatch({ type: STOP_LOADING })
    }
  }
}

export const deleteArticle = ({ id, title }) => {
  return async (dispatch, _, { getFirestore }) => {
    const firestore = getFirestore()

    dispatch({ type: START_LOADING })

    try {
      await firestore.collection('articles').doc(id).delete()

      message.success(`Insight "${title}" deleted!"`)
      dispatch({ type: UPDATE_ARTICLE_SUCCESS })
    } catch (error) {
      message.error(error.message)
      dispatch({ type: UPDATE_ARTICLE_ERROR })
    } finally {
      dispatch({ type: STOP_LOADING })
    }
  }
}

export const fetchArticles = () => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase()
    const firestore = getFirestore()

    dispatch({ type: START_LOADING })

    const uid = firebase.auth().currentUser?.uid
    const state = getState()

    try {
      let articles = await fetchCollection('articles', firestore)
      const myArticles = articles.filter(({ user }) => user.id === uid)

      let categories = []
      let tags = []

      for (let article of articles) {
        categories = categories.concat(article.categories)
        tags = tags.concat(article.tags)
      }

      categories = [...new Set(categories)]
      tags = [...new Set(tags)]

      categories.sort()
      tags.sort()

      if (state.auth.role !== 0) {
        articles = []
      }

      const featuredArticles = {
        en: await firestore
          .collection('featured_articles')
          .doc('en')
          .get()
          .then((doc) => doc.data().id)
          .catch(() => null),
        mv: await firestore
          .collection('featured_articles')
          .doc('mv')
          .get()
          .then((doc) => doc.data().id)
          .catch(() => null),
      }

      dispatch({ type: FETCH_ARTICLES, myArticles, articles, categories, tags, featuredArticles })
    } catch (error) {
      message.error(error.message)
    } finally {
      dispatch({ type: STOP_LOADING })
    }
  }
}

export const setFeaturedArticles = (en, mv) => {
  return async (dispatch, _, { getFirestore }) => {
    const firestore = getFirestore()

    dispatch({ type: START_LOADING })

    try {
      await firestore.collection('featured_articles').doc('en').update({ id: en })
      await firestore.collection('featured_articles').doc('mv').update({ id: mv })

      message.success('Saved featured insights successfully...')

      dispatch(fetchArticles())
    } catch (error) {
      message.error(error.message)
    } finally {
      dispatch({ type: STOP_LOADING })
    }
  }
}

export const fetchCurrentArticle = (id) => {
  return async (dispatch, _, { getFirestore }) => {
    const firestore = getFirestore()

    dispatch({ type: START_LOADING })

    try {
      const article = await firestore.collection('articles').doc(id).get().then(normalizeDoc)

      dispatch({ type: FETCH_CURRENT_ARTICLE, article })
    } catch (error) {
      message.error(error.message)
    } finally {
      dispatch({ type: STOP_LOADING })
    }
  }
}

export const clearCurrentArticle = () => (dispatch) => dispatch({ type: CLEAR_CURRENT_ARTICLE })

export const listenForArticles = () => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase()
    const firestore = getFirestore()

    const collectionRef = firestore.collection('articles')

    const stopListener = collectionRef.onSnapshot(async (snapshot) => {
      const state = getState()

      const currentItems = state.users.all
      const currentUser = firebase.auth().currentUser

      if (currentUser === null) {
        stopListener()
      } else if (!currentItems) {
        dispatch(fetchArticles())
      } else {
        const collectionChanged = currentItems.length !== snapshot.docs.length

        if (collectionChanged) {
          dispatch(fetchArticles())
        } else {
          const items = []

          for (let doc of snapshot.docs) {
            items.push(await normalizeDoc(doc))
          }

          items.sort((a, b) => b.dateObject - a.dateObject)
          currentItems.sort((a, b) => b.dateObject - a.dateObject)

          for (let index in items) {
            if (
              items[index].title !== currentItems[index].title ||
              items[index].status !== currentItems[index].status ||
              items[index].tags.length !== currentItems[index].tags.length ||
              items[index].categories.length !== currentItems[index].categories.length
            ) {
              dispatch(fetchArticles())
              break
            }
          }
        }
      }
    })
  }
}
