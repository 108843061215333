import { LOGIN_SUCCESS, REGISTER_SUCCESS, LOGOUT_SUCCESS, FETCH_AUTH_SUCCESS } from '../action-types'
const initState = {
  error: null,
  role: null,
  fullname: null,
  username: null,
}

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return { ...state, error: null }

    case REGISTER_SUCCESS:
      return { ...state, error: null }

    case LOGOUT_SUCCESS:
      return { ...initState }

    case FETCH_AUTH_SUCCESS:
      const { role, fullname, username, id } = action.user

      return { ...state, error: null, role, fullname, username, id }

    default:
      return state
  }
}

export default authReducer
