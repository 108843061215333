import './AuthSpinner.scss'

const AuthSpinner = () => (
  <div>
    <div className="auth-spinner">
      <div></div>
      <div></div>
    </div>
  </div>
)

export default AuthSpinner
