import {
  CREATE_ARTICLE_SUCCESS,
  CREATE_ARTICLE_ERROR,
  UPDATE_ARTICLE_SUCCESS,
  UPDATE_ARTICLE_ERROR,
  FETCH_ARTICLES,
  FETCH_CURRENT_ARTICLE,
  CLEAR_CURRENT_ARTICLE,
  START_LOADING,
  STOP_LOADING,
} from '../action-types'

const initState = {
  error: false,
  isLoading: false,
  tags: [],
  categories: [],
  all: null,
  myArticles: null,
  current: null,
  featuredArticles: {},
}

const articlesReducer = (state = initState, action) => {
  switch (action.type) {
    case FETCH_ARTICLES:
      return {
        ...state,
        myArticles: action.myArticles,
        all: action.articles,
        tags: action.tags,
        categories: action.categories,
        featuredArticles: action.featuredArticles,
      }

    case CREATE_ARTICLE_SUCCESS:
    case UPDATE_ARTICLE_SUCCESS:
      return { ...state, error: false }

    case CREATE_ARTICLE_ERROR:
    case UPDATE_ARTICLE_ERROR:
      return { ...state, error: true }

    case FETCH_CURRENT_ARTICLE:
      return { ...state, current: action.article }

    case CLEAR_CURRENT_ARTICLE:
      return { ...state, current: null }

    case START_LOADING:
      return { ...state, isLoading: true }

    case STOP_LOADING:
      return { ...state, isLoading: false }

    default:
      return state
  }
}

export default articlesReducer
