import { Fragment, useEffect, useState } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter, Redirect } from 'react-router-dom'
import imageCompression from 'browser-image-compression'
import AuthContent from '../../../../components/layouts/AuthContent'
import { createQnA, editQnA, fetchCurrentQnA, clearCurrentQnA } from '../../../../store/actions/qnas'

import { Row, Col, Form, Input, Select, Button, Divider, Upload, DatePicker, message } from 'antd'
import Editor from '../../../../components/shared/Editor'
import { LeftOutlined } from '@ant-design/icons'
import moment from 'moment'

function QnAsView({
  mode,
  history,
  location,
  firebaseAuth,
  auth,
  isLoading,
  error,
  allTags,
  createQnA,
  editQnA,
  currentQnA,
  fetchCurrentQnA,
  clearCurrentQnA,
}) {
  const [form] = Form.useForm()
  const [initialContent, setInitialContent] = useState('')
  const [content, setContent] = useState('')
  const [filled, setFilled] = useState(false)
  const [images, setImages] = useState([])
  const [date, setDate] = useState(moment())
  const [imageFileList, setImageFileList] = useState([])

  useEffect(() => {
    switch (mode) {
      case 'create':
        const title = new URLSearchParams(location.search).get('message') ?? ''
        const lang = new URLSearchParams(location.search).get('lang') ?? 'en'

        form.setFieldsValue({ author: auth.fullname, thumbnailImage: null, status: false, lang, title })
        break

      case 'edit':
      case 'view':
        if (!filled) {
          const id = location.pathname.split('/').pop()
          fetchCurrentQnA(id)

          if (currentQnA) {
            let { slug, dateObject, title, status, tags, user, thumbnailImage, images, excerpt, content, lang } = currentQnA

            const author = user.fullname

            setImages(images ?? [])
            setImageFileList(images ?? [])
            setDate(moment(dateObject))

            form.setFieldsValue({ slug, id, title, tags, excerpt, author, status, thumbnailImage, lang })

            setInitialContent(content)
            setFilled(true)
          }
        }

        break

      default:
        break
    }
  }, [form, filled, location.search, mode, location.pathname, auth.fullname, currentQnA, fetchCurrentQnA])

  useEffect(() => {
    return () => {
      clearCurrentQnA()
    }
  }, [clearCurrentQnA])

  useEffect(() => {
    if (mode === 'create') {
      const qna = { ...form.getFieldsValue() }

      if (images.length > 0 && !qna.thumbnailImage) {
        form.setFieldsValue({ thumbnailImage: 0 })
      }
    }
  }, [form, images, mode])

  if ((firebaseAuth.isLoaded && firebaseAuth.isEmpty) || auth.role < 0) {
    return <Redirect to="/" />
  }

  const onPreviewImages = async (file) => {
    if (file.name) {
      let src = file.url
      if (!src) {
        src = await new Promise((resolve) => {
          const reader = new FileReader()
          reader.readAsDataURL(file.originFileObj)
          reader.onload = () => resolve(reader.result)
        })
      }
      const image = new Image()
      image.src = src
      const imgWindow = window.open(src)
      imgWindow.document.write(image.outerHTML)
    } else if (file.thumbUrl) {
      window.open(file.thumbUrl, '_blank')
    } else {
      message.error('Unable to preview image')
    }
  }

  const onRemoveImage = (file) => {
    const { name } = file

    setImages((images) => {
      for (let i in images) {
        const image = images[i]

        if (image.name === name) {
          images.splice(i, 1)
        }
      }

      return images
    })

    form.setFieldsValue({ images })
  }

  const onSubmit = async () => {
    const qna = { ...form.getFieldsValue() }

    qna.content = content || initialContent
    qna.tags = qna?.tags ?? []
    qna.date = date.toDate()
    qna.images = [...images]

    if (images.length <= 0) {
      return message.error('You must add a thumbnail image for the highlight...')
    }

    if (lang === 'mv' && !qna.slug) {
      return message.error('You must add a slug for dhivehi insights...')
    }

    if (qna.slug?.includes(' ')) {
      message.error('Slugs must not contain any spaces, instead include dashes.')
      return message.info('Example Slug: this-is-a-slug')
    }

    if (qna.title && qna.excerpt.length > 0 && qna.thumbnailImage !== null) {
      switch (mode) {
        case 'create':
          await createQnA(qna)

          if (!error) history.push('/app/highlights')
          break

        case 'edit':
          const id = location.pathname.split('/').pop()
          qna.id = id

          await editQnA(qna)
          await clearCurrentQnA()
          if (!error) history.push(`/app/highlights/view/${id}`)
          break

        default:
          break
      }
    } else {
      message.error('Please enter the required fields...')
    }
  }

  const title = `${mode[0].toUpperCase() + mode.substring(1)} Highlight`
  let lang = new URLSearchParams(location.search).get('lang') || form.getFieldsValue().lang
  let direction = lang === 'en' ? 'ltr' : 'rtl'
  let fontFamily = lang === 'en' ? 'Josefin Sans' : 'Dhivehi'
  const breadcrumbs = [{ name: 'Highlights', route: '/app/highlights' }, { mode }]
  const isView = mode === 'view'
  const isCreate = mode === 'create'

  if (!lang) {
    if (isCreate) lang = lang || 'en'
    else lang = currentQnA?.lang

    direction = lang === 'en' ? 'ltr' : 'rtl'
    fontFamily = lang === 'en' ? 'Josefin Sans' : 'Dhivehi'
  }

  const statusExtra = currentQnA?.date ? `Published on ${currentQnA.date}` : null

  const editorProps = {
    lang,
    initialValue: initialContent,
    onChange: setContent,
    disabled: isView,
  }

  return (
    <AuthContent title={title} breadcrumbs={breadcrumbs} isLoading={isLoading}>
      <Button type="primary" onClick={() => history.push('/app/highlights')}>
        <LeftOutlined /> Back
      </Button>
      <Divider />
      <Form form={form}>
        <Row gutter={12}>
          <Col xs={12}>
            <Form.Item name="lang" label="Language">
              <Select
                placeholder="Select Language"
                onChange={(value) => (window.location.search = '?lang=' + value)}
                autoComplete="off"
                style={{ color: 'black' }}
                disabled={!isCreate}>
                <Select.Option value={'en'}>{'English'}</Select.Option>
                <Select.Option value={'mv'}>{'Dhivehi'}</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item label="Publish Date">
              <DatePicker style={{ width: '100%', color: 'black' }} onChange={(value) => setDate(value)} value={date} disabled={isView} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col xs={24} sm={12}>
            <Form.Item name="title" label="Title" rules={[{ required: true, message: 'Title is required' }]}>
              <Input placeholder="-----" autoComplete="off" style={{ color: 'black', direction, fontFamily }} disabled={isView} />
            </Form.Item>
            <Form.Item
              name="slug"
              label="Slug"
              rules={lang === 'mv' ? [{ required: true, message: 'Slug is required' }] : null}
              extra="Slugs for english highlights will be automatically generated.">
              <Input placeholder="this-is-a-slug" autoComplete="off" style={{ color: 'black' }} disabled={currentQnA?.status || isView} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item name="status" label="Status" extra={statusExtra} rules={[{ required: true, message: 'Status is required' }]}>
              <Select placeholder="Select status" autoComplete="off" disabled={isView}>
                <Select.Option value={false}>In Progress</Select.Option>
                <Select.Option value={true}>Published</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item name="tags" label="Tags">
              <Select
                mode="tags"
                placeholder="Enter tags (optional)"
                autoComplete="off"
                style={{ color: 'black', direction, fontFamily }}
                disabled={isView}>
                {allTags.map((tag, index) => (
                  <Select.Option key={index} value={tag}>
                    {tag}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item name="images" label="Images">
              <Upload
                accept="image/png, image/jpg, image/jpeg"
                customRequest={({ onSuccess, file: _file }) =>
                  setTimeout(async () => {
                    const { name, type } = _file

                    const options = {
                      maxSizeMB: 0.3,
                    }

                    const compressedFile = await imageCompression(_file, options)

                    const file = new File([compressedFile], name, { type })

                    setImages((files) => [...files, file])
                    onSuccess()
                  }, 0)
                }
                listType="picture-card"
                fileList={imageFileList}
                onChange={({ fileList }) => setImageFileList(fileList)}
                onPreview={onPreviewImages}
                onRemove={onRemoveImage}
                disabled={isView}>
                {images.length < 10 && '+ Upload'}
              </Upload>
            </Form.Item>
          </Col>
          {images.length > 0 ? (
            <Fragment>
              <Col className="fade-in" xs={12}>
                <Form.Item name="thumbnailImage" label="Thumbnail">
                  <Select mode="select" placeholder="Select thumbnail" autoComplete="off" style={{ color: 'black' }} disabled={isView}>
                    {images.map((image, index) => (
                      <Select.Option key={index} value={index}>
                        {image?.name?.length > 50 ? image?.name.slice(50) + '...' : image?.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Fragment>
          ) : (
            <h1>
              <span style={{ color: 'orange', fontSize: 18 }}>*</span>&nbsp;Upload an image to set the thumbnail image
            </h1>
          )}
        </Row>
        <Divider />
        <Row>
          <Col xs={24}>
            <Form.Item name="excerpt" label="Excerpt" rules={[{ required: true, message: 'Title is required' }]}>
              <Input.TextArea
                showCount
                autoSize
                allowClear
                rows={4}
                maxLength="500"
                autoComplete="off"
                style={{ color: 'black', direction, fontFamily }}
                disabled={isView}
              />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <label>Content: </label>
            <Editor {...editorProps} />
          </Col>
        </Row>
        <Divider />
        {mode === 'create' && (
          <Button type="primary" htmlType="submit" onClick={onSubmit} style={{ marginRight: '1em' }}>
            Submit
          </Button>
        )}
        {mode === 'edit' && (
          <Button type="ghost" htmlType="submit" onClick={onSubmit}>
            Save
          </Button>
        )}
        {mode === 'view' && (
          <Button type="dashed" htmlType="submit" onClick={() => history.push(`/app/highlights/edit/${currentQnA.id}`)}>
            Edit
          </Button>
        )}
      </Form>
    </AuthContent>
  )
}

const mapStateToProps = (state) => ({
  firebaseAuth: state.firebase.auth,
  auth: state.auth,
  error: state.qnas.error,
  currentQnA: state.qnas.current,
  allTags: state.qnas.tags,
  isLoading: state.qnas.isLoading,
})

const mapDispatchToProps = (dispatch) => ({
  createQnA: (qna) => dispatch(createQnA(qna)),
  editQnA: (qna) => dispatch(editQnA(qna)),
  fetchCurrentQnA: (id) => dispatch(fetchCurrentQnA(id)),
  clearCurrentQnA: () => dispatch(clearCurrentQnA()),
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(QnAsView)
