import { combineReducers } from 'redux'
import { firebaseReducer } from 'react-redux-firebase'
import { firestoreReducer } from 'redux-firestore'

import app from './app'
import auth from './auth'
import users from './users'
import articles from './articles'
import qnas from './qnas'

const rootReducer = combineReducers({
  app,
  auth,
  users,
  qnas,
  articles,
  firebase: firebaseReducer,
  firestore: firestoreReducer,
})

export default rootReducer
