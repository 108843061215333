import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import './Dashboard.scss'

function Dashboard({ firebaseAuth, auth }) {
  if (firebaseAuth.isEmpty) {
    return <Redirect to="/" />
  }

  if (auth.role === -1) {
    return <Redirect to="/app/settings" />
  } else {
    return <Redirect to="/app/insights" />
  }
}

const mapStateToProps = (state) => ({
  firebaseAuth: state.firebase.auth,
  auth: state.auth,
})

export default connect(mapStateToProps)(Dashboard)
