import { Component } from 'react'

import { Table, Button, Tag } from 'antd'
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons'
import { getColumnSearchProps } from '../../../../components/shared/AntTable'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

class ArticlesTable extends Component {
  state = {
    searchText: '',
    searchedColumn: '',
  }

  getColumnSearchProps = (dataIndex, array = false, dataKey = null) => getColumnSearchProps(dataIndex, this, array, dataKey)

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    })
  }

  handleReset = (clearFilters) => {
    clearFilters()
    this.setState({ searchText: '' })
  }

  render() {
    const { dataSource, auth, noAuthor, isMobile, style } = this.props

    const columns = [
      {
        title: 'title',
        key: 'title',
        ...this.getColumnSearchProps('title'),
        render: (article) => <Link to={`/app/insights/view/${article.id}`}>{article.title}</Link>,
      },
      {
        title: 'Tags',
        key: 'tags',
        dataIndex: 'tags',
        ...this.getColumnSearchProps('tags'),
        render: (tags) => (tags.length <= 0 ? <Tag>No Tags</Tag> : tags.map((tag, i) => <Tag key={i}>{tag}</Tag>)),
      },
      {
        title: 'Status',
        key: 'status',
        sorter: {
          compare: (a, b) => (b.status === a.status ? 1 : -1),
        },
        render: (article) => <span className={`status-${article.status}`}>{article.status ? 'Published' : 'In Progress'}</span>,
      },
      {
        title: 'Language',
        key: 'lang',
        align: 'center',
        sorter: {
          compare: (a, b) => (a.lang !== b.lang ? 1 : -1),
        },
        render: (article) => <span>{article.lang === 'en' ? 'English' : 'Dhivehi'}</span>,
      },
      {
        title: 'Actions',
        align: 'center',
        key: 'actions',
        render: (article) => (
          <div className="ant-table-actions-container">
            {article.status ? (
              <Button className="btn-active" onClick={() => this.props.toggleArticle(article)} block>
                <EyeOutlined />
              </Button>
            ) : (
              <Button className="btn-inactive" onClick={() => this.props.toggleArticle(article)} block>
                <EyeOutlined />
              </Button>
            )}
            <Button className="btn-edit" onClick={() => this.props.editArticle(article)} block>
              <EditOutlined />
            </Button>
            {auth.role === 0 || article.user.id === auth.id ? (
              <Button className="btn-delete" onClick={() => this.props.deleteArticle(article)} block>
                <DeleteOutlined />
              </Button>
            ) : null}
          </div>
        ),
      },
    ]

    if (!noAuthor) {
      columns.splice(1, 0, {
        title: 'Author',
        key: 'author',
        sorter: {
          compare: (a, b) => (b > a ? -1 : 1),
        },
        render: ({ user }) => user.fullname,
      })
    }

    return (
      <Table
        size={isMobile ? 'small' : 'large'}
        style={style}
        rowKey={(article) => article.id}
        loading={this.props.isLoading}
        bordered
        dataSource={dataSource}
        columns={columns}
        pagination={{ pageSize: 10 }}
      />
    )
  }
}

const mapStateToProps = (state) => ({
  isMobile: state.app.isMobile,
})

export default connect(mapStateToProps)(ArticlesTable)
