import { useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter, Switch } from 'react-router-dom'
import { routes, renderRoute } from './routes'

import { updateIsMobile } from './store/actions/app'
import { fetchAuth } from './store/actions/auth'

import PublicLayout from './components/layouts/PublicLayout'
import AuthLayout from './components/layouts/AuthLayout'
import { BackTop } from 'antd'

import './App.scss'

function App({ auth, fetchAuth, updateIsMobile }) {
  const resize = useCallback(() => {
    const isMobile = window.innerWidth <= 768

    updateIsMobile(isMobile)
  }, [updateIsMobile])

  useEffect(() => {
    window.addEventListener('resize', resize)
    resize()

    fetchAuth()

    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [resize, fetchAuth])

  return (
    <BrowserRouter>
      <div className="App">
        <BackTop />
        <Switch>
          {auth.isEmpty ? (
            <PublicLayout>{routes.map((route, index) => renderRoute(route, index))}</PublicLayout>
          ) : (
            <AuthLayout>{routes.map((route, index) => renderRoute(route, index))}</AuthLayout>
          )}
        </Switch>
      </div>
    </BrowserRouter>
  )
}

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
})

const mapDispatchToProps = (dispatch) => ({
  updateIsMobile: (isMobile) => dispatch(updateIsMobile(isMobile)),
  fetchAuth: () => dispatch(fetchAuth()),
})

export default connect(mapStateToProps, mapDispatchToProps)(App)
