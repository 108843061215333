import { useState, useEffect } from 'react'
import { Redirect, Link, withRouter } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'

import { fetchAuth } from '../../../store/actions/auth'
import { listenForArticles } from '../../../store/actions/articles'
import { listenForQnAs } from '../../../store/actions/qnas'

import initMenuItems from './menu-items'
import AuthSpinner from '../../shared/AuthSpinner'
import { Layout, Menu, Avatar, Divider } from 'antd'
import './AuthLayout.scss'

const { Content, Footer, Sider } = Layout

const isCollapsedLS = () => localStorage.getItem('layout-sidebar-collapsed') === 'true'
const setCollapsedLS = (collapsed) => localStorage.setItem('layout-sidebar-collapsed', collapsed)

function AuthLayout({ auth, firebaseAuth, children, isMobile, fetchAuth, listenForArticles, listenForQnAs }) {
  const { pathname } = window.location

  const [menuItems, setMenuItems] = useState(initMenuItems)
  const [collapsed, setCollapsed] = useState(isCollapsedLS())
  const [selectedMenuKeys, setSelectedMenuKeys] = useState(null)
  const [isLoaded, setLoaded] = useState(false)

  useEffect(() => {
    async function fetchData() {
      await fetchAuth()

      if (auth.role !== null) {
        setMenuItems((items) => items.filter((item) => item.authorizeRender(auth.role)))
        setLoaded(true)
      }
    }

    fetchData()
  }, [fetchAuth, auth.role])

  useEffect(() => {
    function findSelectedMenuKeys() {
      for (let i in menuItems) {
        if (pathname.includes(menuItems[i].route)) {
          const foundKeys = [i.toString()]

          setSelectedMenuKeys(foundKeys)
          return
        }
      }
    }

    findSelectedMenuKeys()
  }, [menuItems, pathname])

  useEffect(() => {
    listenForArticles()
    listenForQnAs()
  }, [listenForArticles, listenForQnAs])

  const onCollapse = (collapsed) => {
    setCollapsed(collapsed)
    setCollapsedLS(collapsed)
  }

  const onClickHandler = (e) => {
    const keys = e.keyPath

    setSelectedMenuKeys(keys)

    if (isMobile) {
      setCollapsed(true)
    }
  }

  if ((auth.username === null && localStorage.getItem('AuthLoaded') === 'false') || (firebaseAuth.isLoaded && firebaseAuth.isEmpty)) {
    return <Redirect to="/" />
  }

  if (!isLoaded || !firebaseAuth.isLoaded) {
    return <AuthSpinner />
  }

  return (
    <Layout className="auth-layout">
      <Sider className="auth-sider" collapsible collapsedWidth={isMobile ? 0 : 80} breakpoint="lg" collapsed={collapsed} onCollapse={onCollapse}>
        <div className="sider-container" hidden={isMobile && collapsed}>
          <div className="user-info">
            <Avatar className="avatar">{auth.fullname.charAt(0)}</Avatar>
            <h4 hidden={collapsed}>{auth.fullname}</h4>
            <h5 hidden={collapsed}>@{auth.username}</h5>
          </div>
          <Divider hidden={collapsed} />
          <Menu theme="dark" selectedKeys={selectedMenuKeys} onClick={onClickHandler} mode="inline">
            {menuItems.map(({ title, icon, route }, index) => (
              <Menu.Item className="noselect" key={index}>
                <Link to={route}>
                  {icon}&nbsp;&nbsp;
                  <span>{title}</span>
                </Link>
              </Menu.Item>
            ))}
          </Menu>
        </div>
      </Sider>
      <Layout>
        <div hidden={isMobile && !collapsed}>
          <Content className="auth-layout-content">{children}</Content>
          <Footer>Copyright © {new Date().getFullYear()} Ugail Foundation. All Rights Reserved.</Footer>
        </div>
      </Layout>
    </Layout>
  )
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  firebaseAuth: state.firebase.auth,
  user: state.user,
  isMobile: state.app.isMobile,
})

const mapDispatchToProps = (dispatch) => ({
  fetchAuth: () => dispatch(fetchAuth()),
  listenForArticles: () => dispatch(listenForArticles()),
  listenForQnAs: () => dispatch(listenForQnAs()),
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(AuthLayout)
