import { Tabs } from 'antd'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import { compose } from 'redux'
import AuthContent from '../../../components/layouts/AuthContent'
import Table from './components/Table'
import { listenForUsers, convertToAdmin, convertToAuthor, unassignUser } from '../../../store/actions/users'

import './Users.scss'

function Users({ firebaseAuth, auth, history, location, users, isLoading, listenForUsers, convertToAdmin, convertToAuthor, unassignUser }) {
  useEffect(() => {
    listenForUsers()
  }, [listenForUsers])

  if ((firebaseAuth.isLoaded && firebaseAuth.isEmpty) || auth.role !== 0) {
    return <Redirect to="/" />
  }

  const onTabClick = (key) => {
    switch (key) {
      case '1':
        history.push({ search: '?authors=true' })
        break

      case '2':
        history.push({ search: '?unassigned=true' })
        break

      case '0':
      default:
        history.push({ search: '' })
        break
    }
  }

  const isAuthors = Boolean(new URLSearchParams(location.search).get('authors'))
  const isUnassigned = Boolean(new URLSearchParams(location.search).get('unassigned'))

  const defaultActiveKey = isUnassigned ? '2' : isAuthors ? '1' : '0'

  const tableProps = {
    auth,
    isLoading,
    convertToAdmin,
    convertToAuthor,
    unassignUser,
  }

  const tabs = [
    {
      name: `Admins (${users.admins?.length ?? '0'})`,
      Table: () => <Table dataSource={users.admins} {...tableProps} type={0} />,
    },
    {
      name: `Authors (${users.authors?.length ?? '0'})`,
      Table: () => <Table dataSource={users.authors} {...tableProps} type={0} />,
    },
    {
      name: `Unassigned (${users.unassigned?.length ?? '0'})`,
      Table: () => <Table dataSource={users.unassigned} {...tableProps} type={1} />,
    },
  ]

  return (
    <AuthContent title="Users" breadcrumbs={['Users']}>
      <Tabs onTabClick={onTabClick} defaultActiveKey={defaultActiveKey}>
        {tabs.map(({ name, Table }, index) => (
          <Tabs.TabPane tab={name} key={index}>
            <Table />
          </Tabs.TabPane>
        ))}
      </Tabs>
    </AuthContent>
  )
}

const mapStateToProps = (state) => ({
  firebaseAuth: state.firebase.auth,
  auth: state.auth,
  users: state.users,
})

const mapDispatchToProps = (dispatch) => ({
  listenForUsers: () => dispatch(listenForUsers()),
  convertToAdmin: (user) => dispatch(convertToAdmin(user)),
  convertToAuthor: (user) => dispatch(convertToAuthor(user)),
  unassignUser: (user) => dispatch(unassignUser(user)),
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Users)
