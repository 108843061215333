import { FETCH_USERS, START_LOADING, STOP_LOADING } from '../action-types'

const initState = {
  all: null,
  admins: null,
  authors: null,
  unassigned: null,
  isLoading: false,
}

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case FETCH_USERS:
      return { ...state, all: action.users, admins: action.admins, authors: action.authors, unassigned: action.unassigned }

    case START_LOADING:
      return { ...state, isLoading: true }

    case STOP_LOADING:
      return { ...state, isLoading: false }

    default:
      return state
  }
}

export default authReducer
