import { useState } from 'react'
import { connect } from 'react-redux'
import { login } from '../../../../store/actions/auth'

import { Input } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'

function LoginForm({ login }) {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const onSubmit = () => login({ username, password })

  const onKeyPress = (e) => {
    if (e.key === 'Enter' && !submitDisabled) {
      onSubmit()
    }
  }

  const submitDisabled = username.length <= 0 || password.length <= 0

  return (
    <div className="form-auth">
      <h1>Login</h1>
      <Input
        style={{ marginBottom: '1em' }}
        placeholder="Enter your username"
        prefix={<UserOutlined className="anticon-light" />}
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        onKeyPress={onKeyPress}
      />
      <Input.Password
        placeholder="Enter your password"
        prefix={<LockOutlined className="anticon-light" />}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        onKeyPress={onKeyPress}
      />
      {/* <a href="/auth">Forgot your password?</a> */}
      <button className="btn-auth" onClick={onSubmit} disabled={submitDisabled}>
        Login
      </button>
    </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
  login: (credentials) => dispatch(login(credentials)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm)
