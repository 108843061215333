import { Fragment, useEffect, useState } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter, Redirect } from 'react-router-dom'
import imageCompression from 'browser-image-compression'
import AuthContent from '../../../../components/layouts/AuthContent'
import { createArticle, editArticle, fetchCurrentArticle, clearCurrentArticle } from '../../../../store/actions/articles'

import { Row, Col, Form, Input, Select, Button, Divider, Upload, DatePicker, message } from 'antd'
import Editor from '../../../../components/shared/Editor'
import { LeftOutlined } from '@ant-design/icons'
import moment from 'moment'

function ArticlesView({
  mode,
  history,
  location,
  firebaseAuth,
  auth,
  isLoading,
  error,
  allTags,
  allCategories,
  createArticle,
  editArticle,
  currentArticle,
  fetchCurrentArticle,
  clearCurrentArticle,
}) {
  const [form] = Form.useForm()
  const [initialContent, setInitialContent] = useState('')
  const [content, setContent] = useState('')
  const [filled, setFilled] = useState(false)
  const [images, setImages] = useState([])
  const [date, setDate] = useState(moment())
  const [imageFileList, setImageFileList] = useState([])

  useEffect(() => {
    switch (mode) {
      case 'create':
        form.setFieldsValue({ author: auth.fullname, status: false, lang: new URLSearchParams(location.search).get('lang') ?? 'en' })
        break

      case 'edit':
      case 'view':
        if (!filled) {
          const id = location.pathname.split('/').pop()
          fetchCurrentArticle(id)

          if (currentArticle) {
            let {
              dateObject,
              slug,
              title,
              subHeading,
              status,
              categories,
              tags,
              user,
              featuredImage,
              thumbnailImage,
              images,
              excerpt,
              content,
              lang,
            } = currentArticle

            const author = user.fullname

            setImages(images ?? [])
            setImageFileList(images ?? [])
            setDate(moment(dateObject))

            form.setFieldsValue({ slug, id, title, subHeading, categories, tags, excerpt, author, status, featuredImage, thumbnailImage, lang })

            setInitialContent(content)
            setFilled(true)
          }
        }

        break

      default:
        break
    }
  }, [form, filled, mode, location.pathname, location.search, auth.fullname, currentArticle, fetchCurrentArticle])

  useEffect(() => {
    return () => {
      clearCurrentArticle()
    }
  }, [clearCurrentArticle])

  useEffect(() => {
    if (mode === 'create') {
      const article = { ...form.getFieldsValue() }

      if (images.length > 0 && !article.featuredImage) {
        form.setFieldsValue({ featuredImage: 0, thumbnailImage: 0 })
      }
    }
  }, [form, images, mode])

  if ((firebaseAuth.isLoaded && firebaseAuth.isEmpty) || auth.role < 0) {
    return <Redirect to="/" />
  }

  const onPreviewImages = async (file) => {
    if (file.name) {
      let src = file.url
      if (!src) {
        src = await new Promise((resolve) => {
          const reader = new FileReader()
          reader.readAsDataURL(file.originFileObj)
          reader.onload = () => resolve(reader.result)
        })
      }
      const image = new Image()
      image.src = src
      const imgWindow = window.open(src)
      imgWindow.document.write(image.outerHTML)
    } else if (file.thumbUrl) {
      window.open(file.thumbUrl, '_blank')
    } else {
      message.error('Unable to preview image')
    }
  }

  const onRemoveImage = (file) => {
    const { name } = file

    setImages((images) => {
      for (let i in images) {
        const image = images[i]

        if (image.name === name) {
          images.splice(i, 1)
        }
      }

      return images
    })

    form.setFieldsValue({ images })
  }

  const onSubmit = async () => {
    const article = { ...form.getFieldsValue() }

    article.content = content || initialContent
    article.tags = article?.tags ?? []
    article.categories = article?.categories ?? []
    article.date = date.toDate()
    article.images = [...images]

    if (!article.subHeading) {
      article.subHeading = ''
    }

    if (images.length <= 0) {
      return message.error('You must add a thumbnail image for the insight...')
    }

    if (lang === 'mv' && !article.slug) {
      return message.error('You must add a slug for dhivehi insights...')
    }

    if (article.slug?.includes(' ')) {
      message.error('Slugs must not contain any spaces, instead include dashes.')
      return message.info('Example Slug: this-is-a-slug')
    }

    if (article.title && article.categories.length > 0) {
      switch (mode) {
        case 'create':
          await createArticle(article)

          if (!error) history.push('/app/insights')
          break

        case 'edit':
          const id = location.pathname.split('/').pop()
          article.id = id

          await editArticle(article)
          await clearCurrentArticle()
          if (!error) history.push(`/app/insights/view/${id}`)
          break

        default:
          break
      }
    } else {
      message.error('Please enter the required fields...')
    }
  }

  const title = `${mode[0].toUpperCase() + mode.substring(1)} Insight`
  let lang = new URLSearchParams(location.search).get('lang') || form.getFieldsValue().lang
  let direction = lang === 'en' ? 'ltr' : 'rtl'
  let fontFamily = lang === 'en' ? 'Josefin Sans' : 'Dhivehi'
  const breadcrumbs = [{ name: 'Insights', route: '/app/insights' }, { mode }]
  const isView = mode === 'view'
  const isCreate = mode === 'create'

  if (!lang) {
    if (isCreate) lang = lang || 'en'
    else lang = currentArticle?.lang

    direction = lang === 'en' ? 'ltr' : 'rtl'
    fontFamily = lang === 'en' ? 'Josefin Sans' : 'Dhivehi'
  }

  const statusExtra = currentArticle?.date ? `Published on ${currentArticle.date}` : null

  const editorProps = {
    lang,
    initialValue: initialContent,
    onChange: setContent,
    disabled: isView,
  }

  return (
    <AuthContent title={title} breadcrumbs={breadcrumbs} isLoading={isLoading}>
      <Button type="primary" onClick={() => history.push('/app/insights')}>
        <LeftOutlined /> Back
      </Button>
      <Divider />
      <Form form={form}>
        <Row gutter={12}>
          <Col xs={24} sm={12}>
            <Form.Item name="lang" label="Language">
              <Select
                placeholder="Select Language"
                onChange={(value) => (window.location.search = '?lang=' + value)}
                autoComplete="off"
                style={{ color: 'black' }}
                disabled={!isCreate}>
                <Select.Option value={'en'}>{'English'}</Select.Option>
                <Select.Option value={'mv'}>{'Dhivehi'}</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item label="Publish Date">
              <DatePicker style={{ width: '100%', color: 'black' }} onChange={(value) => setDate(value)} value={date} disabled={isView} />
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Row gutter={12}>
          <Col xs={24} sm={12}>
            <Form.Item name="title" label="Title" rules={[{ required: true, message: 'Title is required' }]}>
              <Input placeholder="-----" autoComplete="off" style={{ color: 'black', direction, fontFamily }} disabled={isView} />
            </Form.Item>
            <Form.Item
              name="slug"
              label="Slug"
              rules={lang === 'mv' ? [{ required: true, message: 'Slug is required' }] : null}
              extra="Slugs for english insights will be automatically generated.">
              <Input placeholder="this-is-a-slug" autoComplete="off" style={{ color: 'black' }} disabled={currentArticle?.status || isView} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item name="status" label="Status" extra={statusExtra} rules={[{ required: true, message: 'Status is required' }]}>
              <Select placeholder="Select status" autoComplete="off" disabled={isView}>
                <Select.Option value={false}>In Progress</Select.Option>
                <Select.Option value={true}>Published</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item name="categories" label="Categories" rules={[{ required: true, message: 'Atleast 1 category is required' }]}>
              <Select
                mode="tags"
                placeholder="Enter categories (min 1)"
                style={{ color: 'black', direction, fontFamily }}
                autoComplete="off"
                disabled={isView}>
                {allCategories.map((category, index) => (
                  <Select.Option key={index} value={category}>
                    {category}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item name="tags" label="Tags">
              <Select
                mode="tags"
                placeholder="Enter tags (optional)"
                autoComplete="off"
                style={{ color: 'black', direction, fontFamily }}
                disabled={isView}>
                {allTags.map((tag, index) => (
                  <Select.Option key={index} value={tag}>
                    {tag}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item name="author" label="Author">
              <Input autoComplete="off" disabled style={{ color: 'black' }} />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item name="images" label="Images" required>
              <Upload
                accept="image/png, image/jpg, image/jpeg"
                customRequest={({ onSuccess, file: _file }) =>
                  setTimeout(async () => {
                    const { name, type } = _file

                    const options = {
                      maxSizeMB: 0.3,
                    }

                    const compressedFile = await imageCompression(_file, options)

                    const file = new File([compressedFile], name, { type })

                    setImages((files) => [...files, file])
                    onSuccess()
                  }, 0)
                }
                listType="picture-card"
                fileList={imageFileList}
                onChange={({ fileList }) => setImageFileList(fileList)}
                onPreview={onPreviewImages}
                onRemove={onRemoveImage}
                disabled={isView}>
                {images.length < 10 && '+ Upload'}
              </Upload>
            </Form.Item>
          </Col>
          {images.length > 0 ? (
            <Fragment>
              <Col className="fade-in" xs={12}>
                <Form.Item name="featuredImage" label="Featured Image">
                  <Select mode="select" placeholder="Select featured image" autoComplete="off" style={{ color: 'black' }} disabled={isView}>
                    {images.map((image, index) => (
                      <Select.Option key={index} value={index}>
                        {image?.name?.length > 50 ? image?.name.slice(50) + '...' : image?.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col className="fade-in" xs={12}>
                <Form.Item name="thumbnailImage" label="Thumbnail">
                  <Select mode="select" placeholder="Select thumbnail" autoComplete="off" style={{ color: 'black' }} disabled={isView}>
                    {images.map((image, index) => (
                      <Select.Option key={index} value={index}>
                        {image?.name?.length > 50 ? image?.name.slice(50) + '...' : image?.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Fragment>
          ) : (
            <h1>
              <span style={{ color: '#ff4d4f', fontSize: 20 }}>*</span>&nbsp;Upload an image to set the featured image
            </h1>
          )}
        </Row>
        <Divider />
        <Row>
          <Col xs={24}>
            <Form.Item name="subHeading" label="Sub-Heading">
              <Input.TextArea
                showCount
                autoSize
                allowClear
                rows={4}
                maxLength="200"
                autoComplete="off"
                style={{ color: 'black', direction, fontFamily }}
                disabled={isView}
              />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item name="excerpt" label="Excerpt" rules={[{ required: true, message: 'Excerpt is required' }]}>
              <Input.TextArea
                showCount
                autoSize
                allowClear
                rows={4}
                maxLength="500"
                autoComplete="off"
                style={{ color: 'black', direction, fontFamily }}
                disabled={isView}
              />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <label>
              <span style={{ color: '#ff4d4f', fontSize: 20 }}>*</span> Content:{' '}
            </label>
            <Editor {...editorProps} />
          </Col>
        </Row>
        <Divider />
        {mode === 'create' && (
          <Button type="primary" htmlType="submit" onClick={onSubmit} style={{ marginRight: '1em' }}>
            Submit
          </Button>
        )}
        {mode === 'edit' && (
          <Button type="ghost" htmlType="submit" onClick={onSubmit}>
            Save
          </Button>
        )}
        {mode === 'view' && (
          <Button type="dashed" htmlType="submit" onClick={() => history.push(`/app/insights/edit/${currentArticle.id}`)}>
            Edit
          </Button>
        )}
      </Form>
    </AuthContent>
  )
}

const mapStateToProps = (state) => ({
  firebaseAuth: state.firebase.auth,
  auth: state.auth,
  error: state.articles.error,
  currentArticle: state.articles.current,
  allTags: state.articles.tags,
  allCategories: state.articles.categories,
  isLoading: state.articles.isLoading,
})

const mapDispatchToProps = (dispatch) => ({
  createArticle: (article) => dispatch(createArticle(article)),
  editArticle: (article) => dispatch(editArticle(article)),
  fetchCurrentArticle: (id) => dispatch(fetchCurrentArticle(id)),
  clearCurrentArticle: () => dispatch(clearCurrentArticle()),
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ArticlesView)
