import { compose } from 'redux'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import { isValidRoute } from '../../routes'

import AuthContent from '../../components/layouts/AuthContent'
import { Button } from 'antd'

function Error404({ auth, match, history }) {
  const returnHome = () => history.push('/')

  const { params } = match

  if (isValidRoute(params)) {
    return null
  } else {
    if (auth.isEmpty) {
      return <Redirect to="/" />
    } else {
      return (
        <AuthContent title="Error 404: Page Not Found" breadcrumbs={['Error']}>
          <h3>Error 404: Page Not Found</h3>
          <br />
          <Button onClick={returnHome}>Return home</Button>
        </AuthContent>
      )
    }
  }
}

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
})

export default compose(withRouter, connect(mapStateToProps))(Error404)
