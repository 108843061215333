import {
  CREATE_QNA_SUCCESS,
  CREATE_QNA_ERROR,
  UPDATE_QNA_SUCCESS,
  UPDATE_QNA_ERROR,
  FETCH_QNAS,
  FETCH_CURRENT_QNA,
  CLEAR_CURRENT_QNA,
  START_LOADING,
  STOP_LOADING,
} from '../action-types'

const initState = {
  error: false,
  isLoading: false,
  tags: [],
  all: null,
  current: null,
  questions: [],
}

const articlesReducer = (state = initState, action) => {
  switch (action.type) {
    case FETCH_QNAS:
      return { ...state, all: action.qnas, tags: action.tags, questions: action.questions }

    case CREATE_QNA_SUCCESS:
    case UPDATE_QNA_SUCCESS:
      return { ...state, error: false }

    case CREATE_QNA_ERROR:
    case UPDATE_QNA_ERROR:
      return { ...state, error: true }

    case FETCH_CURRENT_QNA:
      return { ...state, current: action.qna }

    case CLEAR_CURRENT_QNA:
      return { ...state, current: null }

    case START_LOADING:
      return { ...state, isLoading: true }

    case STOP_LOADING:
      return { ...state, isLoading: false }

    default:
      return state
  }
}

export default articlesReducer
