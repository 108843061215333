// common
export const START_LOADING = 'START_LOADING'
export const STOP_LOADING = 'STOP_LOADING'

// Auth
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const FETCH_AUTH_SUCCESS = 'FETCH_AUTH_SUCCESS'

// App
export const UPDATE_IS_MOBILE = 'UPDATE_IS_MOBILE'

// Articles
export const FETCH_ARTICLES = 'FETCH_ARTICLES'

export const CREATE_ARTICLE_SUCCESS = 'CREATE_ARTICLE_SUCCESS'
export const CREATE_ARTICLE_ERROR = 'CREATE_ARTICLE_ERROR'

export const UPDATE_ARTICLE_SUCCESS = 'UPDATE_ARTICLE_SUCCESS'
export const UPDATE_ARTICLE_ERROR = 'UPDATE_ARTICLE_ERROR'

export const FETCH_CURRENT_ARTICLE = 'FETCH_CURRENT_ARTICLE'
export const CLEAR_CURRENT_ARTICLE = 'CLEAR_CURRENT_ARTICLE'

// QnAs
export const FETCH_QNAS = 'FETCH_QNAS '

export const CREATE_QNA_SUCCESS = 'CREATE_QNA_SUCCESS '
export const CREATE_QNA_ERROR = 'CREATE_QNA_ERROR '

export const UPDATE_QNA_SUCCESS = 'UPDATE_QNA_SUCCESS '
export const UPDATE_QNA_ERROR = 'UPDATE_QNA_ERROR '

export const FETCH_CURRENT_QNA = 'FETCH_CURRENT_QNA '
export const CLEAR_CURRENT_QNA = 'CLEAR_CURRENT_QNA '

// Users
export const FETCH_USERS = 'FETCH_USERS'
