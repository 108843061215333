import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { logout } from '../../../store/actions/auth'

import { Button, Divider } from 'antd'
import AuthContent from '../../../components/layouts/AuthContent'

function Settings({ auth, logout }) {
  if (auth.isEmpty) {
    return <Redirect to="/" />
  }

  return (
    <AuthContent title="Settings" breadcrumbs={['Settings']}>
      <h1>Settings</h1>
      <Divider />
      <Button type="primary" onClick={logout}>
        Logout
      </Button>
    </AuthContent>
  )
}

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
})

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Settings)
