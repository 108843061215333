import { Route } from 'react-router-dom'

// auth
import Dashboard from './pages/auth/Dashboard'
import Articles from './pages/auth/Articles'
import ArticlesView from './pages/auth/Articles/components/ArticlesView'
import FeaturedArticles from './pages/auth/Articles/components/FeaturedArticles'
import QnAs from './pages/auth/QnAs'
import QnAsView from './pages/auth/QnAs/components/QnAsView'
import Settings from './pages/auth/Settings'
import Users from './pages/auth/Users'

// error
import Error404 from './pages/error/Error404'

// public
import Auth from './pages/public/Auth'

const routes = [
  // Auth
  { path: '/', component: Auth },

  // App
  { path: '/app/dashboard', component: Dashboard },
  { path: '/app/insights', component: Articles },
  { path: '/app/insights/featured-articles', component: FeaturedArticles },
  { path: '/app/insights/create', render: (props) => <ArticlesView {...props} mode="create" /> },
  { path: '/app/insights/edit/:id', render: (props) => <ArticlesView {...props} mode="edit" /> },
  { path: '/app/insights/view/:id', render: (props) => <ArticlesView {...props} mode="view" /> },
  { path: '/app/highlights', component: QnAs },
  { path: '/app/highlights/create', render: (props) => <QnAsView {...props} mode="create" /> },
  { path: '/app/highlights/edit/:id', render: (props) => <QnAsView {...props} mode="edit" /> },
  { path: '/app/highlights/view/:id', render: (props) => <QnAsView {...props} mode="view" /> },
  { path: '/app/users', component: Users },
  { path: '/app/settings', component: Settings },

  // Error 404
  { path: '*', component: Error404 },
]

const allRoutes = [...routes]

const renderRoute = (route, index) => {
  const { path, component, render } = route

  if (component) {
    return <Route exact key={index} path={path} component={component} />
  }

  if (render) {
    return <Route exact key={index} path={path} render={render} />
  }

  return null
}

// For the Error404 component
// Checks whether the current pathname belongs to any of the routes and decide whether it is a valid route
const isValidRoute = () => {
  const pathname = window.location.pathname
  const pathSplit = pathname.split('/')
  pathSplit.shift()
  pathSplit.pop()

  let pathStrBuild = ''

  for (let section of pathSplit) {
    pathStrBuild += '/' + section
  }

  for (let i = 0; i < allRoutes.length; i++) {
    const routePath = allRoutes[i].path

    if (routePath.includes('/:id')) {
      if (routePath.replace('/:id', '') === pathStrBuild) {
        return true
      }
    }

    if (pathSplit.length > 1 && pathStrBuild === routePath) {
      return true
    }

    if (pathname === routePath) {
      return true
    }
  }

  return false
}

export { routes, renderRoute, isValidRoute }
