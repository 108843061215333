import { SettingOutlined, BookOutlined, UserOutlined, CommentOutlined } from '@ant-design/icons'

const menuItems = [
  {
    title: 'Insights',
    route: '/app/insights',
    icon: <BookOutlined />,
    authorizeRender: (role) => role >= 0,
  },
  {
    title: 'Highlights',
    route: '/app/highlights',
    icon: <CommentOutlined />,
    authorizeRender: (role) => role === 0,
  },
  {
    title: 'Users',
    route: '/app/users',
    icon: <UserOutlined />,
    authorizeRender: (role) => role === 0,
  },
  {
    title: 'Settings',
    route: '/app/settings',
    icon: <SettingOutlined />,
    authorizeRender: () => true,
  },
]

export default menuItems
