import { Button, Divider } from 'antd'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import { compose } from 'redux'
import AuthContent from '../../../components/layouts/AuthContent'
import { deleteQnA, toggleQnA } from '../../../store/actions/qnas'
import QnAsTable from './components/QnAsTable'
import './QnAs.scss'

function QnAs({ firebaseAuth, auth, history, isLoading, qnas, toggleQnA, deleteQnA }) {
  if (firebaseAuth.isLoaded && firebaseAuth.isEmpty) {
    return <Redirect to="/" />
  }

  if (auth.role !== 0) {
    if (auth.role === 1) {
      return <Redirect to="/app/insights" />
    } else {
      return <Redirect to="/app/settings" />
    }
  }

  const gotoCreate = () => {
    history.push('/app/highlights/create')
  }

  const gotoEdit = (qna) => {
    const path = `/app/highlights/edit/${qna.id}`

    history.push(path)
  }

  const tableProps = {
    auth,
    isLoading,
    editQnA: gotoEdit,
    toggleQnA,
    deleteQnA,
  }

  return (
    <AuthContent title="Highlights" breadcrumbs={['Highlights']}>
      <Button type="primary" onClick={gotoCreate}>
        Create Highlight
      </Button>
      <Divider />
      <QnAsTable dataSource={qnas.all} noAuthor {...tableProps} />,
    </AuthContent>
  )
}

const mapStateToProps = (state) => ({
  firebaseAuth: state.firebase.auth,
  auth: state.auth,
  isLoading: state.qnas.isLoading,
  qnas: state.qnas,
})

const mapDispatchToProps = (dispatch) => ({
  toggleQnA: (qna) => dispatch(toggleQnA(qna)),
  deleteQnA: (qna) => dispatch(deleteQnA(qna)),
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(QnAs)
