import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { register } from '../../../../store/actions/auth'

import { Input, Tooltip, Form } from 'antd'
import { UserOutlined, MailOutlined, FontColorsOutlined, InfoOutlined, LockOutlined } from '@ant-design/icons'

function RegisterForm({ register }) {
  const [form] = Form.useForm()
  const [isDisabled, setDisabled] = useState(true)

  useEffect(() => {
    form.setFieldsValue({
      fullname: '',
      username: '',
      email: '',
      password: '',
      passwordRetyped: '',
    })
  }, [form])

  const onFormChange = () => {
    const fields = Object.values(form.getFieldsValue())

    setDisabled(fields.some((str) => str.length <= 0))
  }

  const onSubmit = (e) => {
    register(form.getFieldValue())
  }

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      onSubmit()
    }
  }

  const rules = [{ required: true, message: 'This field is required', min: 1 }]
  const formItemProps = {
    onKeyPress,
    autoComplete: 'off',
  }

  return (
    <Form.Provider onFormChange={onFormChange}>
      <Form form={form} id="register" className="form-auth">
        <h1>Register</h1>
        <Form.Item name="fullname" rules={rules}>
          <Input placeholder="Enter your full name" prefix={prefixes.fullname} suffix={suffixes.fullname} {...formItemProps} />
        </Form.Item>
        <Form.Item name="username" rules={rules}>
          <Input placeholder="Enter your username" prefix={prefixes.username} suffix={suffixes.username} {...formItemProps} />
        </Form.Item>
        <Form.Item name="email" rules={rules}>
          <Input placeholder="Enter your email" prefix={prefixes.email} {...formItemProps} />
        </Form.Item>
        <Form.Item name="password" rules={rules}>
          <Input.Password placeholder="Enter your password" prefix={prefixes.password} {...formItemProps} />
        </Form.Item>
        <Form.Item name="passwordRetyped" rules={rules}>
          <Input.Password placeholder="Verify your password" prefix={prefixes.password} {...formItemProps} />
        </Form.Item>
        <button className="btn-auth" onClick={onSubmit} disabled={isDisabled}>
          Register
        </button>
      </Form>
    </Form.Provider>
  )
}

// Input props
const prefixes = {
  fullname: <FontColorsOutlined className="anticon-light" />,
  username: <UserOutlined className="anticon-light" />,
  email: <MailOutlined className="anticon-light" />,
  password: <LockOutlined className="anticon-light" />,
}

const suffixes = {
  fullname: (
    <Tooltip title="This will be used to display throughout the app.">
      <InfoOutlined className="anticon-light" />
    </Tooltip>
  ),
  username: (
    <Tooltip title="This will be used to login to the system">
      <InfoOutlined className="anticon-light" />
    </Tooltip>
  ),
}

const mapDispatchToProps = (dispatch) => ({
  register: (credentials) => dispatch(register(credentials)),
})

export default connect(null, mapDispatchToProps)(RegisterForm)
