import { message } from 'antd'
import { fetchCollection, normalizeDoc, uploadImage, getSlug } from './common'
import {
  CREATE_QNA_SUCCESS,
  CREATE_QNA_ERROR,
  UPDATE_QNA_SUCCESS,
  UPDATE_QNA_ERROR,
  FETCH_QNAS,
  FETCH_CURRENT_QNA,
  CLEAR_CURRENT_QNA,
  START_LOADING,
  STOP_LOADING,
} from '../action-types'

export const createQnA = ({ title, date, slug, excerpt, lang, content, images, thumbnailImage, tags, status }) => {
  return async (dispatch, _, { getFirebase, getFirestore }) => {
    const firebase = getFirebase()
    const firestore = getFirestore()

    dispatch({ type: START_LOADING })
    const uid = firebase.auth().currentUser.uid

    try {
      const _images = []

      if (images.length > 0) {
        const storageRef = firebase.storage().ref()

        for (let file of images) {
          const image = await uploadImage(file, uid, storageRef)

          _images.push(image)
        }
      }

      const newQnA = {
        slug: lang === 'en' ? getSlug(title) : slug,
        title,
        excerpt,
        content,
        tags,
        status,
        lang,
        images: _images,
        thumbnailImage,
        date,
        userRef: firestore.collection('users').doc(uid),
        createdDate: new Date(),
        modifiedDate: new Date(),
      }

      await firestore.collection('qnas').add(newQnA)

      message.success(`Highlight "${title}" created!"`)
      dispatch({ type: CREATE_QNA_SUCCESS })
    } catch (error) {
      message.error(error.message)
      dispatch({ type: CREATE_QNA_ERROR })
    } finally {
      dispatch({ type: STOP_LOADING })
    }
  }
}

export const editQnA = ({ id, date, slug, title, images, thumbnailImage, content, excerpt, tags, status }) => {
  return async (dispatch, _, { getFirebase, getFirestore }) => {
    const firebase = getFirebase()
    const firestore = getFirestore()

    dispatch({ type: START_LOADING })
    const uid = firebase.auth().currentUser.uid

    try {
      const _images = []

      if (images.length > 0) {
        const storageRef = firebase.storage().ref()

        for (let file of images) {
          if (file?.ref) {
            _images.push(file)
          } else {
            const image = await uploadImage(file, uid, storageRef)

            _images.push(image)
          }
        }
      }

      const updatedQnA = {
        slug,
        title,
        content,
        excerpt,
        tags,
        date,
        status,
        thumbnailImage,
        images: _images,
        userRef: firestore.collection('users').doc(uid),
        modifiedDate: new Date(),
      }

      await firestore.collection('qnas').doc(id).update(updatedQnA)

      message.success(`Highlight "${title}" edited!"`)
      dispatch({ type: UPDATE_QNA_SUCCESS })
    } catch (error) {
      message.error(error.message)
      dispatch({ type: UPDATE_QNA_ERROR })
    } finally {
      dispatch({ type: STOP_LOADING })
    }
  }
}

export const toggleQnA = ({ id, title, status: _status }) => {
  return async (dispatch, _, { getFirestore }) => {
    const firestore = getFirestore()

    dispatch({ type: START_LOADING })

    try {
      const status = !_status

      let date = new Date()
      if (!status) date = null

      await firestore.collection('qnas').doc(id).update({ status, date })

      message.success(`Highlight "${title}" ${status ? 'Published' : 'In Progress'}!"`)
      dispatch({ type: UPDATE_QNA_SUCCESS })
    } catch (error) {
      message.error(error.message)
      dispatch({ type: UPDATE_QNA_ERROR })
    } finally {
      dispatch({ type: STOP_LOADING })
    }
  }
}

export const deleteQnA = ({ id, title }) => {
  return async (dispatch, _, { getFirestore }) => {
    const firestore = getFirestore()

    dispatch({ type: START_LOADING })

    try {
      await firestore.collection('qnas').doc(id).delete()

      message.success(`Highlight "${title}" deleted!"`)
      dispatch({ type: UPDATE_QNA_SUCCESS })
    } catch (error) {
      message.error(error.message)
      dispatch({ type: UPDATE_QNA_ERROR })
    } finally {
      dispatch({ type: STOP_LOADING })
    }
  }
}

export const deleteQuestion = ({ id }) => {
  return async (dispatch, _, { getFirestore }) => {
    const firestore = getFirestore()

    dispatch({ type: START_LOADING })

    try {
      await firestore.collection('questions').doc(id).delete()

      message.success(`Question deleted!"`)
      dispatch({ type: UPDATE_QNA_SUCCESS })
    } catch (error) {
      message.error(error.message)
      dispatch({ type: UPDATE_QNA_ERROR })
    } finally {
      dispatch({ type: STOP_LOADING })
    }
  }
}

export const fetchQnAs = () => {
  return async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore()

    dispatch({ type: START_LOADING })

    const state = getState()

    try {
      const questions = []
      let qnas = await fetchCollection('qnas', firestore)

      let tags = []

      for (let qna of qnas) {
        tags = tags.concat(qna.tags)
      }

      tags = [...new Set(tags)]

      tags.sort()

      if (state.auth.role !== 0) {
        qnas = []
      }

      dispatch({ type: FETCH_QNAS, qnas, tags, questions })
    } catch (error) {
      message.error(error.message)
    } finally {
      dispatch({ type: STOP_LOADING })
    }
  }
}

export const fetchCurrentQnA = (id) => {
  return async (dispatch, _, { getFirestore }) => {
    const firestore = getFirestore()

    dispatch({ type: START_LOADING })

    try {
      const qna = await firestore.collection('qnas').doc(id).get().then(normalizeDoc)

      dispatch({ type: FETCH_CURRENT_QNA, qna })
    } catch (error) {
      message.error(error.message)
    } finally {
      dispatch({ type: STOP_LOADING })
    }
  }
}

export const clearCurrentQnA = () => (dispatch) => dispatch({ type: CLEAR_CURRENT_QNA })

export const listenForQnAs = () => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase()
    const firestore = getFirestore()

    const collectionRef = firestore.collection('qnas')
    const questionsRef = firestore.collection('questions')

    const stopListener = collectionRef.onSnapshot(async (snapshot) => {
      const state = getState()

      const currentItems = state.qnas.all
      const currentUser = firebase.auth().currentUser

      if (currentUser === null) {
        stopListener()
      } else if (!currentItems) {
        dispatch(fetchQnAs())
      } else {
        const collectionChanged = currentItems.length !== snapshot.docs.length

        if (collectionChanged) {
          dispatch(fetchQnAs())
        } else {
          const items = []

          for (let doc of snapshot.docs) {
            items.push(await normalizeDoc(doc))
          }

          items.sort((a, b) => b.dateObject - a.dateObject)
          currentItems.sort((a, b) => b.dateObject - a.dateObject)

          for (let index in items) {
            if (
              items[index].status !== currentItems[index].status ||
              items[index].title !== currentItems[index].title ||
              items[index].tags.length !== currentItems[index].tags.length
            ) {
              dispatch(fetchQnAs())
              break
            }
          }
        }
      }
    })

    const stopQuestionsListener = questionsRef.onSnapshot(async (snapshot) => {
      const state = getState()

      const currentItems = state.qnas.all
      const currentUser = firebase.auth().currentUser

      if (currentUser === null) {
        stopQuestionsListener()
      } else if (!currentItems) {
        dispatch(fetchQnAs())
      } else {
        const collectionChanged = currentItems.length !== snapshot.docs.length

        if (collectionChanged) {
          dispatch(fetchQnAs())
        }
      }
    })
  }
}
