import { useState } from 'react'
import { Spin } from 'antd'
import { Editor as TinyEditor } from '@tinymce/tinymce-react'

import './Editor.scss'

function Editor({ initialValue, onChange, lang, disabled = false }) {
  const [isLoaded, setLoaded] = useState(false)

  const handleEditorChange = (e) => {
    const content = e.target.getContent()

    return onChange(content)
  }

  if (!lang)
    return (
      <div className={`editor ${lang}`}>
        <Spin className="spinner" />
      </div>
    )

  const props = {
    init: init(lang),
    apiKey: process.env.REACT_APP_TINYMCE_API_KEY,
    initialValue,
    disabled,
    onInit: () => setLoaded(true),
    onChange: handleEditorChange,
  }

  return (
    <div className={`editor ${lang}`}>
      {<TinyEditor {...props} />}
      {!isLoaded ? <Spin className="spinner" /> : null}
    </div>
  )
}

const init = (lang) => {
  return {
    height: 500,
    menubar: 'file edit insert view format tools help',
    paste_data_images: true,
    plugins: [
      'advlist autolink lists link image',
      'charmap print preview anchor help',
      'searchreplace visualblocks code',
      'insertdatetime media table paste wordcount fullscreen',
    ],
    toolbar:
      'undo redo |  formatselect | fontselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | help | fullscreen',
    font_formats: lang === 'mv' ? 'Dhivehi=Dhivehi;' : 'English=Josefin Sans, sans-serif;',
    content_style:
      lang === 'mv' ? 'body { direction: rtl; font-family: Dhivehi;  }' : 'body { font-family: Josefin Sans, sans-serif; direction: ltr; }',
  }
}

export default Editor
