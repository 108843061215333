import { Input, Button } from 'antd'
import Highlighter from 'react-highlight-words'
import { SearchOutlined } from '@ant-design/icons'

import './AntTable.scss'

export const getColumnSearchProps = (dataIndex, component, array = false, dataKey = null) => ({
  filterDropdown: (props) => <FilterDropdown dataIndex={dataIndex} dataKey={dataKey} component={component} {...props} />,
  filterIcon: (filtered) => <FilterIcon filtered={filtered} />,
  onFilter: (value, record) => {
    if (array && dataKey) {
      let searchString = ''

      for (let object of record[dataIndex]) {
        const string = object[dataKey]

        if (typeof string === 'string') {
          searchString += string
        } else {
          console.warn('Search string is invalid')
          return
        }
      }

      return searchString.toLowerCase().includes(value.toLowerCase())
    }

    if (dataKey) {
      return record[dataKey][dataIndex].toString().toLowerCase().includes(value.toLowerCase())
    }

    return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
  },
  onFilterDropdownVisibleChange: (visible) => visible && setTimeout(() => component.searchInput.select()),
  render: (text) => {
    if (component.state.searchedColumn === dataIndex) {
      const highlightStyle = {
        backgroundColor: '#ffc069',
        padding: 0,
      }

      return <Highlighter highlightStyle={highlightStyle} searchWords={[component.state.searchText]} autoEscape textToHighlight={text.toString()} />
    } else {
      return text
    }
  },
})

const FilterDropdown = ({ dataIndex, component, setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
  <div className="filter-dropdown">
    <Input
      className="search-input"
      ref={(node) => (component.searchInput = node)}
      placeholder={`Search ${dataIndex}`}
      value={selectedKeys[0]}
      onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
      onPressEnter={() => component.handleSearch(selectedKeys, confirm, dataIndex)}
    />
    <Button
      className="search-btn"
      type="primary"
      onClick={() => component.handleSearch(selectedKeys, confirm, dataIndex)}
      icon={<SearchOutlined />}
      size="small">
      Search
    </Button>
    <Button className="reset-btn" onClick={() => component.handleReset(clearFilters)} size="small">
      Reset
    </Button>
  </div>
)

const FilterIcon = (filtered) => {
  const style = {
    color: filtered ? '#1890ff' : undefined,
  }

  return <SearchOutlined style={style} />
}
