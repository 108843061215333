import { UPDATE_IS_MOBILE } from '../action-types'

const initState = {
  isMobile: false,
}

const appReducer = (state = initState, action) => {
  switch (action.type) {
    case UPDATE_IS_MOBILE:
      return { ...state, isMobile: action.isMobile }

    default:
      return state
  }
}

export default appReducer
