import { Component, Fragment } from 'react'
import moment from 'moment'

import { Table, Button, Tooltip } from 'antd'
import { UserDeleteOutlined, UserSwitchOutlined } from '@ant-design/icons'
import { getColumnSearchProps } from '../../../../components/shared/AntTable'

class AdminsTable extends Component {
  state = {
    searchText: '',
    searchedColumn: '',
  }

  getColumnSearchProps = (dataIndex) => getColumnSearchProps(dataIndex, this)

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    })
  }

  handleReset = (clearFilters) => {
    clearFilters()
    this.setState({ searchText: '' })
  }

  render() {
    const { dataSource, auth, type } = this.props

    const columns = [
      {
        title: 'Full Name',
        key: 'fullname',
        dataIndex: 'fullname',
        ...this.getColumnSearchProps('fullname'),
      },
      {
        title: 'Username',
        key: 'username',
        dataIndex: 'username',
        ...this.getColumnSearchProps('username'),
      },
      {
        title: 'Register Date',
        sorter: {
          compare: (a, b) => moment(b.registerDate).toDate() - moment(a.registerDate).toDate(),
        },
        showSorterTooltip: false,
        render: (user) => moment(user.registerDate).format('DD/MM/YYYY hh:mm:ssA'),
      },
      {
        title: 'Actions',
        align: 'center',
        key: 'actions',
        render: (user) => (
          <div className="ant-table-actions-container">
            {type ? (
              <Fragment>
                <Button className="btn-edit" onClick={() => this.props.convertToAdmin(user)} block>
                  <Tooltip title="Convert to Admin">
                    <UserSwitchOutlined />
                  </Tooltip>
                </Button>
                <Button className="btn-inactive" onClick={() => this.props.convertToAuthor(user)} block>
                  <Tooltip title="Convert to Author">
                    <UserSwitchOutlined />
                  </Tooltip>
                </Button>
              </Fragment>
            ) : auth.id === user.id ? null : (
              <Button className="btn-delete" onClick={() => this.props.unassignUser(user)} block>
                <Tooltip title={type === 0 ? 'Revoke Admin' : 'Revoke Author'}>
                  <UserDeleteOutlined />
                </Tooltip>
              </Button>
            )}
          </div>
        ),
      },
    ]

    if (auth.role !== 0) {
      return null
    }

    return <Table loading={this.props.isLoading} bordered dataSource={dataSource} columns={columns} pagination={{ pageSize: 10 }} />
  }
}

export default AdminsTable
