import { useEffect } from 'react'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'

import { Layout } from 'antd'
import AuthSpinner from '../../shared/AuthSpinner'
import './PublicLayout.scss'

const { Header, Content, Footer } = Layout

function PublicLayout({ auth, children }) {
  useEffect(() => {
    document.title = 'Ugail.live Admin - Auth'
  }, [])

  if (!auth.isEmpty) {
    return <Redirect to="/app/dashboard" />
  }

  if (!auth.isLoaded) {
    return <AuthSpinner />
  }

  return (
    <Layout className="home-layout">
      <Header className="home-header">
        <Link to="/">
          <div className="app-name">
            <h1 className="header-title">
              <span>U</span>gail.live Admin
            </h1>
          </div>
        </Link>
      </Header>
      <Content>{children}</Content>
      <Footer>Copyright © {new Date().getFullYear()} Ugail Foundation. All Rights Reserved.</Footer>
    </Layout>
  )
}

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
})

export default connect(mapStateToProps)(PublicLayout)
