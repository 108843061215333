export const LoginPrompt = ({ togglePanel }) => (
  <div className="mobile-prompts-container">
    <h1>Welcome Back!</h1>
    <p>Please login to use the app</p>
    <button className="btn-auth ghost" onClick={togglePanel}>
      Login
    </button>
  </div>
)

export const RegisterPrompt = ({ togglePanel }) => (
  <div className="mobile-prompts-container">
    <h1>Don't have an account?</h1>
    <p>Register and contact our organisation to verify!</p>
    <button className="btn-auth ghost" onClick={togglePanel}>
      Register
    </button>
  </div>
)
