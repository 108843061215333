import { Button, Divider, Tabs } from 'antd'
import { Fragment } from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import { compose } from 'redux'
import AuthContent from '../../../components/layouts/AuthContent'
import { listenForArticles, deleteArticle, toggleArticle } from '../../../store/actions/articles'
import './Articles.scss'
import ArticlesTable from './components/ArticlesTable'

function Articles({ firebaseAuth, auth, history, location, isLoading, articles, toggleArticle, deleteArticle }) {
  if (firebaseAuth.isLoaded && firebaseAuth.isEmpty) {
    return <Redirect to="/" />
  }

  if (auth.role < 0) {
    return <Redirect to="/app/settings" />
  }

  const gotoCreate = () => {
    history.push('/app/insights/create')
  }

  const gotoFeaturedArticles = () => {
    history.push('/app/insights/featured-articles')
  }

  const gotoEdit = (article) => {
    const path = `/app/insights/edit/${article.id}`

    history.push(path)
  }

  const onTabClick = (key) => {
    switch (key) {
      case '1':
        history.push({ search: '?all=true' })
        break

      case '0':
      default:
        history.push({ search: '' })
        break
    }
  }

  const isAllArticles = Boolean(new URLSearchParams(location.search).get('all'))

  const defaultActiveKey = isAllArticles ? '1' : '0'

  const tableProps = {
    auth,
    isLoading,
    editArticle: gotoEdit,
    toggleArticle,
    deleteArticle,
  }

  const tabs = [
    {
      name: `My Insights (${articles.myArticles?.length ?? '0'})`,
      Table: () => <ArticlesTable dataSource={articles.myArticles} noAuthor {...tableProps} />,
    },
    {
      name: `All Insights (${articles.all?.length ?? '0'})`,
      Table: () => <ArticlesTable dataSource={articles.all} {...tableProps} />,
    },
  ]

  return (
    <AuthContent title="Insights" breadcrumbs={['Insights']}>
      <Button type="primary" onClick={gotoCreate}>
        Create Insight
      </Button>
      {auth.role === 0 && (
        <Button type="ghost" style={{ marginLeft: '.5em' }} onClick={gotoFeaturedArticles}>
          Featured Insights
        </Button>
      )}
      <Divider />
      {auth.role === 1 ? (
        <Fragment>
          <label>My Insights:</label>
          <ArticlesTable style={{ marginTop: '1em' }} dataSource={articles.myArticles} noAuthor {...tableProps} />
        </Fragment>
      ) : (
        <Tabs onTabClick={onTabClick} defaultActiveKey={defaultActiveKey}>
          {tabs.map(({ name, Table }, index) => (
            <Tabs.TabPane tab={name} key={index}>
              <Table />
            </Tabs.TabPane>
          ))}
        </Tabs>
      )}
    </AuthContent>
  )
}

const mapStateToProps = (state) => ({
  firebaseAuth: state.firebase.auth,
  auth: state.auth,
  isLoading: state.articles.isLoading,
  articles: state.articles,
})

const mapDispatchToProps = (dispatch) => ({
  listenForArticles: () => dispatch(listenForArticles()),
  toggleArticle: (article) => dispatch(toggleArticle(article)),
  deleteArticle: (article) => dispatch(deleteArticle(article)),
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Articles)
