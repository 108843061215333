import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import { compose } from 'redux'
import AuthContent from '../../../../components/layouts/AuthContent'
import { LeftOutlined } from '@ant-design/icons'
import { Button, Divider, Select, Row, Col } from 'antd'
import { useEffect, useState } from 'react'
import { setFeaturedArticles } from '../../../../store/actions/articles'

function Articles({ firebaseAuth, auth, isLoading, articles, allArticles, setFeaturedArticles, history }) {
  const [english, setEnglish] = useState(null)
  const [dhivehi, setDhivehi] = useState(null)

  useEffect(() => {
    setEnglish(articles.en)
    setDhivehi(articles.mv)
  }, [articles])

  if (firebaseAuth.isLoaded && firebaseAuth.isEmpty) {
    return <Redirect to="/" />
  }

  if (auth.role !== 0) {
    return <Redirect to="/app/insights" />
  }

  const save = () => {
    setFeaturedArticles(english, dhivehi)
  }

  const breadcrumbs = [{ name: 'Insights', route: '/app/insights' }, 'Featured Insights']

  return (
    <AuthContent title="Featured Insights" breadcrumbs={breadcrumbs} isLoading={isLoading}>
      <Button type="primary" onClick={() => history.push('/app/insights')}>
        <LeftOutlined /> Back
      </Button>
      <Divider>Current Featured Insights</Divider>
      <Row>
        <Col xs={24}>
          <h4>English</h4>
          <Select placeholder="Select Insight" autoComplete="off" style={{ minWidth: '400px' }} onChange={setEnglish} value={english}>
            {allArticles
              ?.filter((article) => article.lang === 'en')
              .map((article, key) => (
                <Select.Option key={key} value={article.id}>
                  {article.title}
                </Select.Option>
              ))}
          </Select>
        </Col>
        <Col xs={24}>
          <h4 style={{ marginTop: '1em' }}>Dhivehi</h4>
          <Select
            placeholder="Select Insight"
            autoComplete="off"
            style={{ minWidth: '400px', fontFamily: 'dhivehi' }}
            onChange={setDhivehi}
            value={dhivehi}>
            {allArticles
              ?.filter((article) => article.lang === 'mv')
              .map((article, key) => (
                <Select.Option key={key} value={article.id} style={{ fontFamily: 'dhivehi' }}>
                  {article.title}
                </Select.Option>
              ))}
          </Select>
        </Col>
      </Row>
      <div style={{ marginTop: '25%' }}></div>
      <Divider />
      <Button onClick={save}>Save</Button>
    </AuthContent>
  )
}

const mapStateToProps = (state) => ({
  firebaseAuth: state.firebase.auth,
  auth: state.auth,
  isLoading: state.articles.isLoading,
  articles: state.articles.featuredArticles,
  allArticles: state.articles.all,
})

const mapDispatchToProps = (dispatch) => ({
  setFeaturedArticles: (en, mv) => dispatch(setFeaturedArticles(en, mv)),
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Articles)
