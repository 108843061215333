export const isEmpty = (string) => string === undefined || string === null || string.length <= 0
export const generateUniqueKey = () => Math.floor(Math.random() * (999999999 - 100000000) + 100000000)

export async function fetchCollection(name, firestore) {
  try {
    const snapshot = await firestore.collection(name).get()
    const items = []

    for (let doc of snapshot.docs) {
      const normalizedDoc = await normalizeDoc(doc)

      items.push(normalizedDoc)
    }

    return items.sort((a, b) => b?.dateObject - a?.dateObject)
  } catch (message) {
    throw Error(message)
  }
}

// async return { ref, url }
export const uploadImage = async (file, uid, storageRef) => {
  const uniqueKey = generateUniqueKey()
  const imageUploadLocationRef = storageRef.child(`${uid}/${uniqueKey}`)

  const ref = await imageUploadLocationRef.put(file).then((snapshot) => snapshot.ref.fullPath)
  const url = await storageRef.child(ref).getDownloadURL()

  return {
    uid: uniqueKey,
    name: file.name,
    status: 'done',
    ref,
    url,
  }
}

export const parseFullname = (fullname) => {
  const lowercased = fullname.toLowerCase()

  const names = lowercased.split(' ')

  let parsedFullname = ''

  for (let name of names) {
    const nameCapitalized = name.charAt(0).toUpperCase() + name.slice(1)
    parsedFullname += nameCapitalized + ' '
  }

  return parsedFullname.substring(0, parsedFullname.length - 1)
}

export const getSlug = (title) => {
  return title
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '')
}

export const slugify = (slug) => {
  return slug.toLowerCase().replace(/[^\w-]+/g, '')
}

const formatDate = (date) => {
  let hours = date.getHours()
  let minutes = date.getMinutes()
  const ampm = hours >= 12 ? 'pm' : 'am'

  hours = hours % 12
  hours = hours ? hours : 12
  minutes = minutes < 10 ? '0' + minutes : minutes

  const dateStr = date.toDateString()
  const time = `${hours}:${minutes}${ampm}`

  return `${dateStr} ${time}`
}

export const normalizeDoc = async (doc) => {
  const data = doc.data()

  const normalizedDoc = {
    id: doc.id,
    ...data,
  }

  if (data?.date) {
    normalizedDoc.date = formatDate(data.date.toDate())
    normalizedDoc.dateObject = data.date.toDate()
  }

  if (data?.createdDate) {
    normalizedDoc.createdDate = data.createdDate.toDate()
  }

  if (data?.modifiedDate) {
    normalizedDoc.modifiedDate = data.modifiedDate.toDate()
  }

  if (data?.registerDate) {
    normalizedDoc.registerDate = data.registerDate.toDate()
  }

  if (normalizedDoc?.userRef) {
    normalizedDoc.user = await normalizedDoc.userRef.get().then(normalizeDoc)
  }

  return normalizedDoc
}

export const regex = {
  LettersAndSpacesOnly: /^[a-zA-Z][a-zA-Z\s]*$/,
  Username: /^[a-z]([._-]?[a-z0-9]+)*$/,
  Email: /\S+@\S+\.\S+/,
}

export const errors = {
  BLANK_FIELDS: 'Please fill in all the fields.',
  INVALID_FULLNAME: 'Invalid Full Name. Your full name must not contain any numbers and must have a space.',
  INVALID_USERNAME:
    'Invalid Username. Your username must be between 4-12 characters, it must start with a letter and may contain _, - or a dot inbetween (no spaces). The last character must either be a letter or a number.',
  INVALID_EMAIL: 'Invalid email. Eg: user@email.com',
  TAKEN_USERNAME: 'The username is already taken.',
  TAKEN_EMAIL: 'The email is already taken.',
  PASSWORD_MISMATCH: 'Please ensure that your passwords are the same.',
  PASSWORD_LENGTH: 'Please ensure that your password is between 6-25 characters.',
}
