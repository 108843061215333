import { message } from 'antd'
import { fetchCollection } from './common'
import { FETCH_USERS, START_LOADING, STOP_LOADING } from '../action-types'

export const fetchUsers = () => {
  return async (dispatch, _, { getFirestore }) => {
    const firestore = getFirestore()

    dispatch({ type: START_LOADING })

    try {
      const users = await fetchCollection('users', firestore)

      const admins = users.filter(({ role }) => role === 0)
      const authors = users.filter(({ role }) => role === 1)
      const unassigned = users.filter(({ role }) => role === -1)

      dispatch({ type: FETCH_USERS, users, admins, authors, unassigned })
    } catch (error) {
      message.error(error.message)
    } finally {
      dispatch({ type: STOP_LOADING })
    }
  }
}

export const listenForUsers = () => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase()
    const firestore = getFirestore()

    const collectionRef = firestore.collection('users')

    const stopListener = collectionRef.onSnapshot((snapshot) => {
      const state = getState()

      const currentItems = state.users.all
      const currentUser = firebase.auth().currentUser

      if (currentUser === null) {
        stopListener()
      } else if (!currentItems) {
        dispatch(fetchUsers())
      } else {
        const collectionChanged = currentItems.length !== snapshot.docs.length

        if (collectionChanged) {
          dispatch(fetchUsers())
        }
      }
    })
  }
}

export const convertToAdmin = (user) => {
  return async (dispatch, _, { getFirestore }) => {
    const firestore = getFirestore()

    dispatch({ type: START_LOADING })

    try {
      await firestore.collection('users').doc(user.id).update({ role: 0 })
      dispatch(fetchUsers())
    } catch (error) {
      message.error(error.message)
    } finally {
      dispatch({ type: STOP_LOADING })
    }
  }
}

export const convertToAuthor = (user) => {
  return async (dispatch, _, { getFirestore }) => {
    const firestore = getFirestore()

    dispatch({ type: START_LOADING })

    try {
      await firestore.collection('users').doc(user.id).update({ role: 1 })
      dispatch(fetchUsers())
    } catch (error) {
      message.error(error.message)
    } finally {
      dispatch({ type: STOP_LOADING })
    }
  }
}

export const unassignUser = (user) => {
  return async (dispatch, _, { getFirestore }) => {
    const firestore = getFirestore()

    dispatch({ type: START_LOADING })

    try {
      await firestore.collection('users').doc(user.id).update({ role: -1 })
      dispatch(fetchUsers())
    } catch (error) {
      message.error(error.message)
    } finally {
      dispatch({ type: STOP_LOADING })
    }
  }
}
