import { useState } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import LoginForm from './components/LoginForm'
import RegisterForm from './components/RegisterForm'
import { LoginPrompt, RegisterPrompt } from './components/prompts'
import { Row } from 'antd'
import './Auth.scss'

function Auth({ auth, isMobile }) {
  const [showRegister, setRegister] = useState(false)

  const togglePanel = async () => {
    await setRegister((value) => !value)
    window.scrollTo(0, 0)
  }

  if (!auth.isEmpty) {
    return <Redirect to="/app/dashboard" />
  }

  if (isMobile) {
    return (
      <div>
        <Row>{showRegister ? <RegisterForm togglePanel={togglePanel} /> : <LoginForm />}</Row>
        <Row>{showRegister ? <LoginPrompt togglePanel={togglePanel} /> : <RegisterPrompt togglePanel={togglePanel} />}</Row>
      </div>
    )
  }

  const containerClassName = showRegister ? 'right-panel-active' : ''

  return (
    <div className={`container ${containerClassName}`}>
      <div className="form-container register-container">
        <RegisterForm />
      </div>
      <div className="form-container login-container">
        <LoginForm />
      </div>
      <div className="overlay-container">
        <div className="overlay">
          <div className="overlay-panel overlay-left">
            <LoginPrompt togglePanel={togglePanel} />
          </div>
          <div className="overlay-panel overlay-right">
            <RegisterPrompt togglePanel={togglePanel} />
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  isMobile: state.app.isMobile,
})

export default connect(mapStateToProps)(Auth)
