import { isEmpty, parseFullname, errors, regex, fetchCollection, normalizeDoc } from './common'
import { LOGIN_SUCCESS, REGISTER_SUCCESS, LOGOUT_SUCCESS, FETCH_AUTH_SUCCESS } from '../action-types'
import { message } from 'antd'

export const register = (credentials) => {
  return async (dispatch, _, { getFirebase, getFirestore }) => {
    const stopLoading = message.loading('Registering...', 0)

    const firebase = getFirebase()
    const firestore = getFirestore()

    const { fullname, username: _username, email: _email, password, passwordRetyped } = credentials
    const username = _username.toLowerCase()
    const email = _email.toLowerCase()
    const names = fullname.split(' ')

    const users = await fetchCollection('users', firestore)

    const usernameExists = users.some((user) => user.username === username)
    const emailExists = users.some((user) => user.email === email)

    if (isEmpty(fullname) || isEmpty(username) || isEmpty(email) || isEmpty(password) || isEmpty(passwordRetyped)) {
      message.error(errors.BLANK_FIELDS)
    } else if (!regex.LettersAndSpacesOnly.test(fullname) || names.length < 2) {
      message.error(errors.INVALID_FULLNAME)
    } else if (!regex.Username.test(username) || username.length < 4 || username.length > 12) {
      message.error(errors.INVALID_USERNAME)
    } else if (!regex.Email.test(email)) {
      message.error(errors.INVALID_EMAIL)
    } else if (password !== passwordRetyped) {
      message.error(errors.PASSWORD_MISMATCH)
    } else if (password.length < 6 || password.length > 25) {
      message.error(errors.PASSWORD_LENGTH)
    } else if (usernameExists) {
      message.error(errors.TAKEN_USERNAME)
    } else if (emailExists) {
      message.error(errors.TAKEN_EMAIL)
    } else {
      try {
        const response = await firebase.auth().createUserWithEmailAndPassword(email, password)

        const { uid } = response.user

        const newUser = {
          fullname: parseFullname(fullname),
          username,
          email,
          role: users.length <= 0 ? 0 : -1,
          registerDate: new Date(),
        }

        await firestore.collection('users').doc(uid).set(newUser)
        dispatch({ type: REGISTER_SUCCESS })
      } catch (error) {
        message.error(error.message)
      }
    }

    stopLoading()
  }
}

export const login = (credentials) => {
  return async (dispatch, _, { getFirebase, getFirestore }) => {
    const stopLoading = message.loading('Logging in...', 0)

    const firebase = getFirebase()
    const firestore = getFirestore()

    const users = await fetchCollection('users', firestore)
    const user = users.find(({ username }) => username === credentials.username.toLowerCase())

    if (user) {
      try {
        await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
        await firebase.auth().signInWithEmailAndPassword(user.email, credentials.password)

        dispatch(fetchAuth())
        dispatch({ type: LOGIN_SUCCESS })
      } catch {
        message.error('Please enter the correct password.')
      }
    } else {
      message.error('This username does not exist.')
    }

    stopLoading()
  }
}

export const logout = () => {
  return async (dispatch, _, { getFirebase }) => {
    const firebase = getFirebase()

    try {
      await firebase.auth().signOut()
      dispatch({ type: LOGOUT_SUCCESS })
    } catch (error) {
      message.error(error.message)
    }
  }
}

export const fetchAuth = () => {
  return async (dispatch, _, { getFirebase, getFirestore }) => {
    const firebase = getFirebase()
    const firestore = getFirestore()

    const currentUser = firebase.auth().currentUser

    if (currentUser) {
      const user = await firestore
        .collection('users')
        .doc(currentUser.uid)
        .get()
        .then(normalizeDoc)
        .catch(() => dispatch(logout()))

      if (user) {
        dispatch({ type: FETCH_AUTH_SUCCESS, user })
        localStorage.setItem('AuthLoaded', 'true')
      } else {
        dispatch(logout())
        localStorage.setItem('AuthLoaded', 'false')
      }
    }
  }
}
